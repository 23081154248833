<!-- 薪资发放 -->
<template>
  <section>
    <section class="search-container">
      <a-row class="m-t-16">
        <a-col :span="3"
          ><span style="color:#fff" class="m-r-18">材料名称:</span
          ><span style="color:#93BBF3">{{ materialName }}</span></a-col
        >
        <a-col :span="3"
          ><span style="color:#fff" class="m-r-18">当前总库存:</span
          ><span style="color:#93BBF3">{{ totalQty }}</span></a-col
        >
        <a-col :span="5"
          ><span style="color:#fff" class="m-r-18">品牌:</span
          ><span style="color:#93BBF3">{{ brand }}</span></a-col
        >
        <a-col :span="3"
          ><span style="color:#fff" class="m-r-18">规格型号:</span
          ><span style="color:#93BBF3">{{ type }}</span></a-col
        >
        <a-col :span="3"
          ><span style="color:#fff" class="m-r-18">计量单位:</span
          ><span style="color:#93BBF3">{{ unit }}</span></a-col
        >
      </a-row>
      <a-form layout="inline" :form="form" class="m-t-14" @submit="submitForm">
        <a-form-item label="领取人">
          <a-input
            autocomplete="off"
            v-decorator="['receiver']"
            placeholder="请输入"
          />
        </a-form-item>

        <a-form-item label="经手人">
          <a-input
            autocomplete="off"
            v-decorator="['operator']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="领取人">
          <a-input
            autocomplete="off"
            v-decorator="['receiver']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="领取时间">
          <a-range-picker style="width:224px" v-decorator="['time']">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>
        <a-form-item label="仓库">
          <a-select
           class="search-select"
            placeholder="请选择"
            @change="handleChangeWarehouse"
            v-decorator="['warehouseId']"
          >
            <a-select-option
              v-for="item in warehouseData"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button class="m-r-8" html-type="submit"
            ><a-icon type="search" />查询</a-button
          >
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
        </a-form-item>
      </a-form>
      <!-- <a-row class="right-action-panel m-t-14">
        <a-button class="item"><a-icon type="export" />导出</a-button>
        <a-button class="item">新增领出</a-button>
      </a-row> -->
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
          @change="changePageNum"
        :pagination="{ showQuickJumper: false, total: list.total }"
      >
        <template slot="operation" slot-scope="">
          <router-link to="/mms/inout/stock/stockData">
            <a style="color:#529aff">查看</a>
          </router-link>

          <a-divider style="color:#222" type="vertical" />
          <a style="color:#529aff">删除</a>
        </template>
      </a-table>
    </section>
  </section>
</template>

<script>
export default {
   props: ["skuIdInfo"],
  data() {
    return {
      form: this.$form.createForm(this),
      brand: "",
      type: "",
      unit: "",
      totalQty: "",
      endTime: "",
      operator: "",
      receiver: "",
      startTime: "",
      skuId: "",
      warehouseData: [],
      warehouseId: "",
      materialName: "",
      list: {
        total: 0,
        pageNum: 1,
        columns: [
          { title: "序号", dataIndex: "ind", align: "center" },
          {
            title: "出库单据编号",
            dataIndex: "outStorageCode",
            align: "center"
          },
          { title: "领取日期", dataIndex: "outTime", align: "center" },
          { title: "领取人", dataIndex: "receiver", align: "center" },
          { title: "经手人", dataIndex: "operator", align: "center" },
          {
            title: "领用数量",
            dataIndex: "outQty",
            align: "center"
          },

          { title: "存放仓库", dataIndex: "warehouseName", align: "center" }
        ],
        data: []
      }
    };
  },
  created() {
    this.getStockDetail();
    this.warehouse();
    this.getOutStorageDetailList();
  },
   computed: {
    skuIdData() {
      return this.skuIdInfo;
    },
  },
  methods: {
    getStockDetail() {
      this.skuId = this.$route.query.id;
      this.$api.getStockDetail(this.skuId).then(res => {
        if (res.code == "200") {
          let stock = res.data.stock;
          this.materialName = stock.materialName;
          this.totalQty = res.data.totalQty;
          this.brand = stock.brand;
          this.type = stock.type;
          this.unit = stock.unit;
        }
      });
    },
    // 列表
    getOutStorageDetailList() {
      let params = {
        receiver: this.receiver,
        current: this.list.pageNum,

        startTime: this.startTime,
        endTime: this.endTime,
        warehouseId: this.warehouseId,
        operator: this.operator,
        skuId: this.skuIdData
      };

      this.$api.getOutStorageDetailList(params).then(res => {
        if (res.code == "200") {
          this.list.total = res.data.total;
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.ind = index + 1;
              item.key = index + 1;
            });
          }
          this.list.data = res.data.records;
        }
      });
    },
    // 获取仓库id
    handleChangeWarehouse(val) {
      this.warehouseId = val;
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.getInStorageDetailList();
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then(res => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.form.getFieldsValue();
      console.log(formVal);
      this.startTime = formVal.time ? formVal.time[0].format("YYYY-MM-DD") : "";
      this.endTime = formVal.time ? formVal.time[1].format("YYYY-MM-DD") : "";
      this.operator = formVal.operator;
      this.receiver = formVal.receiver;
      this.receiver = formVal.receiver;
      this.warehouseId = formVal.warehouseId;
      this.getOutStorageDetailList();
    },
    // 重置
    reset() {
      this.startTime = "";
      this.endTime = "";
      this.warehouseId = "";
      this.operator = "";
      this.receiver = "";
       this.receiver='';
      this.list.pageNum = 1;
      this.form.resetFields();
      this.getOutStorageDetailList();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100% !important;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
</style>