<template>
  <section>
    <section class="nav-container m-t-18">
      <a
        @click="navActiveKey = item.key"
        :class="item.key == navActiveKey ? 'active' : ''"
        v-for="item in navMap"
        :key="item.key"
        >{{ item.name }}</a
      >
    </section>

    <section>
      <basic-info @skuId="getSkuId" v-if="navActiveKey === 1" />
      <enter-record :skuIdInfo="getSkuIdInfo"  v-if="navActiveKey === 2" />
      <out-record :skuIdInfo="getSkuIdInfo" v-if="navActiveKey === 3" />
    </section>
  </section>
</template>


<script>
import basicInfo from "./basicInfo";
import enterRecord from "./enterRecord";
import outRecord from "./outRecord";
export default {
  data() {
    return {
      navMap: [
        { name: "基本信息", key: 1 },
        { name: "入库记录", key: 2 },
        { name: "出库记录", key: 3 },
      ],
      navActiveKey: 1,
    };
  },
  components: {
    basicInfo,
    enterRecord,
    outRecord,
  },
  created() {
    this.getStockDetail();
  },
  
  methods: {
    getStockDetail() {
      this.stockId = this.$route.query.id;
      this.$api.getStockDetail(this.stockId).then((res) => {
        if (res.code == "200") {
          let stock = res.data.stock;
          // console.log(stock);
          this.dataSource = [
            {
              key: "1",
              ind: "1",
              brand: stock.brand,
              type: stock.type,
              unit: stock.unit,
              warehouseName: stock.warehouseName,
              stockQty: stock.stockQty,
            },
          ];
          this.materialName = stock.materialName;
          this.totalQty = res.data.totalQty;
        }
      });
    },
    getSkuId(val) {
      console.log( val );
      this.getSkuIdInfo = val;
    },
  },
};
</script>