<template>
  <section>
    <a-row class="m-t-16">
      <a-col :span="3"
        ><span style="color: #fff" class="m-r-18">材料名称:</span
        ><span style="color: #93BBF3">{{ materialName }}</span></a-col
      >
      <a-col :span="3"
        ><span style="color: #fff" class="m-r-18">当前总库存:</span
        ><span style="color: #93BBF3">{{ totalQty }}</span></a-col
      >
    </a-row>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="dataSource"
        size="small"
        class="m-t-18"
        :columns="columns"
        :pagination="false"
      >
        <template slot="operation" slot-scope="">
          <router-link to="/mms/inout/stock/stockData">
            <a style="color: #529aff">查看</a>
          </router-link>

          <a-divider style="color: #222" type="vertical" />
          <a style="color: #529aff">删除</a>
        </template>
      </a-table>
    </section>
  </section>
</template>
<script>
export default {
  data() {
    return {
      stockId: "",
      materialName: "",
      totalQty: "",
      dataSource: [],
      columns: [
        { title: "序号", dataIndex: "ind", align: "center" },
        { title: "品牌", dataIndex: "brand", align: "center" },
        { title: "规则型号", dataIndex: "type", align: "center" },
        { title: "计量单位", dataIndex: "unit", align: "center" },
        { title: "存放仓库", dataIndex: "warehouseName", align: "center" },
        { title: "当前库存", dataIndex: "stockQty", align: "center" },
      ],
    };
  },
  created() {
    this.getStockDetail();
  },
  methods: {
    getStockDetail() {
      this.stockId = this.$route.query.id;
      this.$api.getStockDetail(this.stockId).then((res) => {
        if (res.code == "200") {
          let stock = res.data.stock;
          this.dataSource = [
            {
              key: "1",
              ind: "1",
              brand: stock.brand,
              type: stock.type,
              unit: stock.unit,
              warehouseName: stock.warehouseName,
              stockQty: stock.stockQty,
            },
          ];
          this.materialName = stock.materialName;
          this.totalQty = res.data.totalQty;

          // 像父组件传值
          this.$emit("skuId",stock.skuId);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  line-height: 40px;
  padding-bottom: 4px;
  border-bottom: 1px solid #2f538f;
  font-size: 15px;
  color: #529aff;
  margin-bottom: 8px;
  font-weight: bold;
}
</style>