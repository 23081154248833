
<template>
  <section>
    <section class="search-container">
      <a-row class="m-t-16">
        <a-col :span="3"
          ><span style="color: #fff" class="m-r-18">材料名称:</span
          ><span style="color: #93BBF3">{{ materialName }}</span></a-col
        >
        <a-col :span="3"
          ><span style="color: #fff" class="m-r-18">当前总库存:</span
          ><span style="color: #93BBF3">{{ totalQty }}</span></a-col
        >
        <a-col :span="5"
          ><span style="color: #fff" class="m-r-18">品牌:</span
          ><span style="color: #93BBF3">{{ brand }}</span></a-col
        >
        <a-col :span="3"
          ><span style="color: #fff" class="m-r-18">规格型号:</span
          ><span style="color: #93BBF3">{{ type }}</span></a-col
        >
        <a-col :span="3"
          ><span style="color: #fff" class="m-r-18">计量单位:</span
          ><span style="color: #93BBF3">{{ unit }}</span></a-col
        >
      </a-row>
      <a-form layout="inline" :form="form" class="m-t-14" @submit="submitForm">
        <a-form-item>
          <a-select default-value="采购订单编号" @change="handleChange">
            <a-select-option value="1"> 采购订单编号 </a-select-option>
            <a-select-option value="2"> 批次订单编号 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-input
            autocomplete="off"
            v-decorator="['orderNum']"
            placeholder="请输入"
          />
        </a-form-item>

        <a-form-item label="供应商">
          <a-input
            autocomplete="off"
            v-decorator="['supplier']"
            placeholder="请输入"
          />
        </a-form-item>

        <a-form-item label="送货人">
          <a-input
            autocomplete="off"
            v-decorator="['deliveryman']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="经办人">
          <a-input
            autocomplete="off"
            v-decorator="['operator']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="入库时间">
          <a-range-picker style="width: 224px" v-decorator="['time']">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-item>
        <a-form-item label="仓库">
          <a-select
            placeholder="请选择"
            class="search-select"
            @change="handleChangeWarehouse"
            v-decorator="['warehouseId']"
          >
            <a-select-option
              v-for="item in warehouseData"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button class="m-r-8" html-type="submit"
            ><a-icon type="search" />查询</a-button
          >
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
        </a-form-item>
      </a-form>
      <!-- <a-row class="right-action-panel m-t-14">
        <a-button class="item"><a-icon type="export" />导出</a-button>
        <router-link to="/mms/inout/warehouseIn/addWareHouseIn">
          <a-button class="item">新增入库</a-button>
        </router-link>
      </a-row> -->
    </section>

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{ showQuickJumper: false, total: list.total }"
      >
        <template slot="operation" slot-scope="">
          <router-link to="/mms/inout/stock/stockData">
            <a style="color: #529aff">查看</a>
          </router-link>

          <a-divider style="color: #222" type="vertical" />
          <a style="color: #529aff">删除</a>
        </template>
      </a-table>
    </section>
  </section>
</template>

<script>
export default {
  props: ["skuIdInfo"],
  data() {
    return {
      form: this.$form.createForm(this),
      orderType: "1", //订单类型
      warehouseData: [],
      startTime: "",
      skuId: "",
      endTime: "",
      orderCode: "",
      batchCode: "",
      warehouseId: "",
      deliveryman: "",
      operator: "",
      supplier: "",
      current: "",
      totalQty: "",
      materialName: "",
      brand: "",
      type: "",
      unit: "",
      list: {
        total: 0,
        pageNum: 1,
        columns: [
          { title: "序号", dataIndex: "ind", align: "center" },
          { title: "采购订单编号", dataIndex: "orderCode", align: "center" },
          { title: "批次订单编号", dataIndex: "batchCode", align: "center" },
          { title: "供货商", dataIndex: "supplier", align: "center" },
          // { title: "材料名称", dataIndex: "ind4", align: "center" },
          // { title: "计量单位", dataIndex: "ind5", align: "center" },
          //{ title: "入库数量", dataIndex: "ind6", align: "center" },
          //{ title: "材料金额", dataIndex: "ind7", align: "center" },
          {
            title: "入库订单编号",
            dataIndex: "inStorageCode",
            align: "center",
          },
          { title: "入库数量", dataIndex: "receivedQty", align: "center" },
          { title: "存放仓库", dataIndex: "warehouseName", align: "center" },
          { title: "送货人", dataIndex: "deliveryman", align: "center" },
          { title: "经办人", dataIndex: "operator", align: "center" },
          { title: "入库日期", dataIndex: "inTime", align: "center" },
          // {
          //   title: "操作",
          //   dataIndex: "operation",
          //   align: "center",
          //   scopedSlots: { customRender: "operation" }
          // }
        ],
        data: [],
      },
    };
  },
  created() {
    this.getStockDetail();

    this.warehouse();
    this.getInStorageDetailList();
  },
  computed: {
    skuIdData() {
      return this.skuIdInfo;
    },
  },
  methods: {
    getStockDetail() {
      this.skuIds = this.$route.query.id;
      this.$api.getStockDetail(this.skuIds).then((res) => {
        if (res.code == "200") {
          let stock = res.data.stock;
          this.materialName = stock.materialName;
          this.totalQty = res.data.totalQty;
          this.brand = stock.brand;
          this.type = stock.type;
          this.unit = stock.unit;
        }
      });
    },
    // 选择编号类型
    handleChange(type) {
      this.orderType = type;
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then((res) => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    // 列表
    getInStorageDetailList() {
      let params = {
        batchCode: this.batchCode,
        current: this.list.pageNum,
        deliveryman: this.deliveryman,
        startTime: this.startTime,
        endTime: this.endTime,
        warehouseId: this.warehouseId,
        operator: this.operator,
        orderCode: this.orderCode,
        supplier: this.supplier,
        skuId: this.skuIdData,
      };

      this.$api.getInStorageDetailList(params).then((res) => {
        if (res.code == "200") {
          this.list.total = res.data.total;
          if (res.data.records && res.data.records.length > 0) {
            res.data.records.forEach((item, index) => {
              item.ind = index + 1;
              item.key = index + 1;
            });
          }
          this.list.data = res.data.records;
        }
      });
    },
    // 获取仓库id
    handleChangeWarehouse(val) {
      this.warehouseId = val;
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.getInStorageDetailList();
    },
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.form.getFieldsValue();
      console.log(formVal);
      this.startTime = formVal.time ? formVal.time[0].format("YYYY-MM-DD") : "";
      this.endTime = formVal.time ? formVal.time[1].format("YYYY-MM-DD") : "";
      this.orderCode = this.orderType == "1" ? formVal.orderNum : "";
      this.batchCode = this.orderType == "1" ? "" : formVal.orderNum;
      this.warehouseId = formVal.warehouseId;
      this.deliveryman = formVal.deliveryman;
      this.operator = formVal.operator;
      this.supplier = formVal.supplier;
      this.current = this.list.pageNum;

      this.getInStorageDetailList();
    },
    // 重置
    reset() {
      this.startTime = "";
      this.endTime = "";
      this.orderCode = "";
      this.batchCode = "";
      this.warehouseId = "";
      this.deliveryman = "";
      this.operator = "";
      this.supplier = "";
      this.list.pageNum = 1;
      this.form.resetFields();
      this.getInStorageDetailList();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100% !important;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
</style>